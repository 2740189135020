<template>
    <div class="cardEdit" style="background-color: #f2f2f2; margin-top: 8px">
        <el-card shadow="never">
            <el-page-header @back="goBack" />
        </el-card>
        <el-card shadow="never" style="margin-top: 8px">
            <el-form
                @submit.native.prevent
                :inline="true"
                style="text-align: right"
                v-model="form"
                size="small"
                label-width="85px"
            >
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="机构组名称">
                            <el-input v-model="meta.deptGroupName" readonly />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="机构名称">
                            <el-select v-model="card.deptCode" @change="handleQueryPayType">
                                <el-option value="" label="请选择" />
                                <el-option
                                    v-for="dept in meta.deptCodes"
                                    :label="dept.name"
                                    :key="dept.code"
                                    :value="dept.code"
                                />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="创建人">
                            <el-input readonly v-model="card.creator" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="创建时间">
                            <el-date-picker v-model="card.createDate" type="date" value-format="yyyy-MM-dd" readonly />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24" style="text-align: left"> <ef-remark v-model="card.remark" /> </el-col>1
                </el-row>
            </el-form>
        </el-card>
        <el-card shadow="never" style="margin-top: 8px; text-align: left">
            <el-button type="primary" @click="save" size="small">保存</el-button>
        </el-card>
        <el-card shadow="never" style="margin-top: 8px">
            <el-table
                id="printMe"
                border
                stripe
                style="width: 100%"
                :data="tableData"
                size="mini"
                :highlight-current-row="true"
                max-height="520"
            >
                <el-table-column label="全选" type="selection" width="60" align="center" fixed="left" />
                <el-table-column label="序号" width="60" type="index" fixed="left" />
                <el-table-column label="会员卡号" width="240" prop="cardPrintNo" />
                <el-table-column label="卡状态" width="120" prop="status">
                    <template slot-scope="scope"
                        ><span>{{ scope.row.status | status }}</span></template
                    >
                </el-table-column>
                <el-table-column label="会员名称" width="120" prop="memberName" />
                <el-table-column label="手机号" width="160" prop="mobile" />
                <el-table-column label="储蓄余额" width="160" prop="createTime">
                    <template slot-scope="scope">
                        <span>{{ (scope.row.money / 10000).toFixed(2) }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="积分" width="160" prop="point" />
                <el-table-column label="工本费" width="160" prop="inWorkCost">
                    <template slot-scope="scope">
                        <el-input v-model="scope.row.inWorkCost" size="mini" />
                    </template>
                </el-table-column>
                <el-table-column label="退款方式" width="160" prop="point">
                    <template slot-scope="scope">
                        <el-select v-model="scope.row.outWorkCostPayTemplateCode" filterable size="mini">
                            <el-option label="请选择" value="" />
                            <el-option
                                v-for="payTypes in meta.payTypes"
                                :label="payTypes.name"
                                :value="payTypes.code"
                                :key="payTypes.code"
                            />
                        </el-select>
                    </template>
                </el-table-column>
            </el-table>
        </el-card>
    </div>
</template>
<script>
import UrlUtils from 'js/UrlUtils';
import Utils from 'js/Util';
import EfRemark from 'components/EfRemark';
import MoneyUtils from 'js/MoneyUtils';

export default {
    name: 'CardReturnCard',
    components: { EfRemark },
    props: ['form'],
    data() {
        return {
            tableData: [],
            card: {
                createDate: new Date(),
                creator: this.$store.state.session.name,
                remark: '',
                deptCode: '',
                maxRefundMoney: 0,
            },
            meta: {
                groups: [],
                deptCodes: [],
                payTypes: [],
                deptGroupName: '',
                deptGroupCode: '',
            },
            loadingDeptGroupFlag: true,
            url: {
                queryGroups: '/system/deptGroup/list',
                queryDeptByGroup: '/system/dept/deptTree',
                queryDetail: '/member/card/info_c/',
                save: '/member/card/refundCard',
                deptMemberMoneyDetail: '/member/rechargeAndRefundDetail',
            },
        };
    },
    filters: {
        status(status) {
            if (typeof status == 'undefined') {
                return '';
            }
            if (status == '1') {
                return '正常';
            }
            if (status == '2') {
                return '丢失';
            }
            if (status == '3') {
                return '作废';
            }
        },
    },
    mounted() {
        this.handleQueryDetail();
    },
    methods: {
        handleChangeGroup() {
            const _this = this;
            UrlUtils.QueryRemote(
                this,
                this.url.queryDeptByGroup + '?deptGroupCode=' + _this.meta.deptGroupCode,
                (rst) => {
                    _this.meta.deptCodes = rst;
                }
            );
        },
        handleQueryDetail() {
            const _this = this;
            UrlUtils.QueryRemote(this, this.url.queryDetail + _this.form.code, (rst) => {
                rst.inWorkCost = _this.fmtTwo(rst.inWorkCost);
                _this.meta.deptGroupCode = rst.deptGroupCode || '';
                _this.meta.deptGroupName = rst.deptGroupName || '';
                _this.tableData.push(rst);
                _this.handleChangeGroup();
            });
        },
        handleQueryPayType() {
            const _this = this;
            const _params = {
                params: {
                    memberCode: this.form.memberCode,
                    deptGroupCode: this.form.deptGroupCode,
                    deptCode: this.card.deptCode,
                    flag: 1,
                },
            };
            Utils.queryTable(_this, _this.url.deptMemberMoneyDetail, _params, (data) => {
                _this.meta.payTypes = data.data.payTypeParamList;
                _this.tableData[0].currentMoney = data.data.currentMoney;
                _this.tableData[0].cardNo = data.data.cardNo || '';
                _this.tableData[0].status = data.data.status;
                _this.card.maxRefundMoney = MoneyUtils.moneyToYuan(data.data.currentMoney);
            });
        },

        save() {
            const _this = this;
            if (!this.card.deptCode) {
                this.$message.error('请选择机构名称');
                return;
            }
            const disable =
                _this.tableData[0].inWorkCost != 0 &&
                typeof _this.tableData[0].outWorkCostPayTemplateCode == 'undefined';
            if (disable) {
                _this.$message.error('请选择退款方式');
                return;
            }
            const _params = {
                remark: _this.card.remark,
                deptGroupCode: _this.form.deptGroupCode,
                deptCode: _this.card.deptCode,
                cardPrintNo: _this.tableData[0].cardPrintNo,
                newCardPrintNo: _this.tableData[0].newCardPrintNo,
                code: _this.tableData[0].code,
                memberCode: _this.tableData[0].memberCode,
                outWorkCost: MoneyUtils.moneyToDb(_this.tableData[0].inWorkCost),
                outWorkCostPayTemplateCode: _this.tableData[0].outWorkCostPayTemplateCode,
            };
            UrlUtils.PatchRemote(_this, _this.url.save, _params, null, () => {
                this.$message.success('退卡成功');
                _this.goBackAndReload();
            });
        },
    },
};
</script>

<style scoped>
.el-input {
    width: auto;
}
</style>
